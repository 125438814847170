import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorPawel as author } from 'data/authors';

import img from 'img/blog/updates/profile-page-fn.png';
import img1 from 'img/blog/updates/profile-page.png';
import styled from 'styled-components';

const Container = styled.div`
  h3,
  h2 {
    font-weight: 700;
    letter-spacing: 0.01em;
    margin: 2em 0 0.8em;
    line-height: 2rem;
  }
  h3 {
    font-size: 1.1rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  .img-screen {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  }
`;

const Content = () => {
  return (
    <Container>
      <p>
        On the profile page, you can change your name and password, and thanks to the information
        about your role in the organization, we can develop the product according to your needs.
      </p>

      <img
        src={img1}
        alt="Your profile page"
        className="img-screen img-fluid justify-content-center mb-5 mt-3"
        title="Change your name and password"
        loading="lazy"
      />

      <p className="mt-3">
        We hope you like it! Other new improvements are coming soon. If you think something needs
        improvement, or do you have an idea for functionality? Write to us:{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Container>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Your profile page',
  url: '/blog/your-profile-page/',
  description: 'On the profile page, you can change your name and password.',
  author,
  img,
  date: '2019-08-30',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    group={frontmatter.group}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    descriptionSocial={frontmatter.descriptionSocial || frontmatter.description}
  />
);
